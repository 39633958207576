import React, { useState, useMemo } from 'react'
import styled, { css } from 'styled-components'
import Link from 'next/link'
import { useSnackbar } from 'notistack'
import { format } from 'date-fns'
import Tooltip from '@mui/material/Tooltip'
import { useSession } from 'context/Session'
import {
  Button,
  Tag,
  Modal,
  FollowButton,
  KarmaMedal,
} from 'components/elements'
import { getCloudFlareImgVariant } from 'components/helpers/withSizes'
import { Form } from 'components/collections'
import { viewContentStyle } from 'components/layouts/Theme/tiny'
import { BiLink } from 'react-icons/bi'
import { GoComment } from 'react-icons/go'
import { MdPictureAsPdf, MdInfo } from 'react-icons/md'
import { reportArticle } from '../modules/datasource'

export const Container = styled.div`
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  flex: 1;
  align-items: center;
  padding: 0 152px;
  ${({ theme }) => theme.media.tablet} {
    padding: 0 32px;
  }
  ${({ theme }) => theme.media.phone} {
    padding: 0 16px;
  }
`

export const Content = styled.div`
  flex: 1;
  max-width: 704px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .tox-tinymce {
    border: 0;
  }
`
export const PaidContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Title = styled.h1`
  ${({ theme }) => theme.font.h1};
  font-size: 46px;
  line-height: 67px;
  margin: 32px 0;
`

export const ShareWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
export const OptionsContent = styled.div`
  display: flex;
  align-items: center;
`

export const CommentsCount = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
    `}
`
export const CommentIcon = styled(GoComment)`
  font-size: 22px;
  margin-right: 6px;
  margin-top: 5px;
`

export const ArticleImg = styled.div`
  position: relative;
  overflow: hidden;
  height: 469px;
  width: 100%;
  background: ${({ theme }) => theme.colors.lightGrey};
  color: ${({ theme }) => theme.colors.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  margin-bottom: 8px;
  ${({ src }) =>
    src &&
    css`
      background-image: ${`url(${src})`};
      background-size: cover;
      background-position: center;
    `};

  ${({ theme }) => theme.media.phone} {
    width: 100%;
    height: 193px;
    min-height: 193px;
    max-width: unset;
  }
`

const AuthorContainer = styled.div`
  display: flex;
  margin: 48px 0;
  ${({ theme }) => theme.media.phone} {
    flex-direction: column;
  }
`
const AuthorPic = styled.img`
  height: 66px;
  width: 66px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
  margin-right: 12px;
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 12px;
  }
`
const AuthorPlaceholder = styled.div`
  height: 66px;
  width: 66px;
  border-radius: 50%;
  margin-right: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  text-transform: uppercase;
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 12px;
  }
`
const AuthorContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  ${({ theme }) => theme.media.phone} {
    margin-bottom: 12px;
  }
`
const AuthorName = styled.a`
  cursor: pointer;
  width: fit-content;
`
const AuthorDesc = styled.div`
  color: ${({ theme }) => theme.colors.grey};
`
const OpinionTag = styled.div`
  background: ${({ theme }) => theme.colors.primaryL};
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  height: fit-content;
  width: fit-content;
`

export const InfoIcon = styled(MdInfo)`
  font-size: 22px;
  margin-left: 6px;
`
const AuthorHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
`

const UserKarmaMedal = styled(KarmaMedal)`
  color: ${({ theme }) => theme.colors.primary};
  margin-left: 10px;
`

const articleTypeMap = {
  Opinion: () => (
    <Tooltip title="Note that views expressed in this opinion article are the writer's personal views and not necessarily those of TrialSite.">
      <OpinionTag>
        Opinion Article <InfoIcon />
      </OpinionTag>
    </Tooltip>
  ),
  Journalist: () => (
    <Tooltip title="Independent Journalist review">
      <OpinionTag>
        Journalist Article <InfoIcon />
      </OpinionTag>
    </Tooltip>
  ),
}

export const Author = props => {
  const { profile, publishedAt, type } = props
  const { user } = useSession()

  const TagComponent = articleTypeMap[type]

  const hasFollow = useMemo(() => {
    return user && user?.profile?.username !== profile?.username
  }, [profile, user])

  return (
    <AuthorContainer key={`author-${profile?.username}`}>
      {profile?.profileImage ? (
        <AuthorPic
          src={getCloudFlareImgVariant(profile?.profileImage, {
            default: 'profileSmall',
          })}
        />
      ) : (
        <AuthorPlaceholder>{profile?.username[0]}</AuthorPlaceholder>
      )}
      <AuthorContent>
        <AuthorHeader>
          <Link disabled href={`/p/${profile?.username}`} passHref>
            <AuthorName>{profile?.username}</AuthorName>
          </Link>
          <Tooltip title={`${profile?.karma || 0} Merits`}>
            <div style={{ height: 32 }}>
              <UserKarmaMedal size="large" value={profile?.karma || 0} />
            </div>
          </Tooltip>
          {hasFollow && (
            <FollowButton
              style={{ marginLeft: 20 }}
              username={profile?.username}
            />
          )}
        </AuthorHeader>
        {profile?.position && (
          <AuthorDesc>
            {`${profile?.position} at ${profile?.company}${
              profile?.singleLineStatement
                ? ` | ${profile?.singleLineStatement}`
                : ''
            }`}
          </AuthorDesc>
        )}
        <AuthorDesc>
          {publishedAt &&
            format(new Date(publishedAt), 'MMM. d, yyyy, h:mm aaaa')}
        </AuthorDesc>
      </AuthorContent>
      {TagComponent && <TagComponent />}
    </AuthorContainer>
  )
}

const JournalistDisclaimerContainer = styled.div`
  padding: 16px;
  border: solid 1px ${({ theme }) => theme.colors.grey2};
  color: ${({ theme }) => theme.colors.error};
  ${({ theme }) => theme.font.body2}
  font-style: italic;
`

export const JournalistDisclaimer = () => {
  return (
    <JournalistDisclaimerContainer>
      The opinions expressed in this article are solely those of the author and
      do not necessarily reflect the views or opinions of TrialSite Inc., its
      staff, or its affiliates. The information provided is based on the
      author&apos;s understanding and knowledge at the time of writing and is
      subject to change as new information becomes available. Readers are
      encouraged to seek diverse perspectives and perform their own research
      before forming their own opinion.
    </JournalistDisclaimerContainer>
  )
}

export const DraftTag = styled.div`
  background-color: ${({ theme }) => theme.colors.grey};
  padding: 2px 15px;
  border-radius: 100px;
  color: white;
  align-self: flex-start;
  margin-top: 20px;
  margin-bottom: -30px;
  text-align: center;
`

export const ReferenceTitle = styled.div`
  ${({ theme }) => theme.font.h2}
  margin-bottom: 36px;
`

export const RefList = styled.ul`
  padding-inline-start: 0;
  padding-bottom: 36px;
`
export const ListItem = styled.li`
  display: flex;
  align-items: center;
`
export const ItemDesc = styled.a`
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.black};
  border-bottom: solid 1px ${({ theme }) => theme.colors.black};
  padding: 4px 0;
  text-decoration: none;
  &:hover {
    color: ${({ theme }) => theme.colors.primary};
  }
`
export const TagLabel = styled.div`
  display: flex;
  align-items: center;
`
export const LinkIcon = styled(BiLink)`
  font-size: 22px;
  margin-right: 10px;
`
export const PdfIcon = styled(MdPictureAsPdf)`
  font-size: 22px;
  margin-right: 10px;
`

export const TagsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -4px;
  padding-bottom: 36px;
`
export const TagItem = styled(Tag)`
  margin: 4px;
  padding: 12px;
  cursor: pointer;
`

export const ArticleContent = styled.div`
  font-family: 'Amiri', serif;
  font-size: 20px;
  line-height: 30px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: -0.5px;
  ${viewContentStyle}
  word-wrap: break-word;

  a {
    color: ${({ theme }) => theme.colors.primary};
  }
  img {
    max-width: 100%;
    height: auto;
  }

  figure {
    margin: 0;
  }

  table {
    border-collapse: collapse;
    tr + tr {
      border-top: solid 1px ${({ theme }) => theme.colors.grey2};
    }
    td {
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  iframe {
    max-width: 100%;
  }
  ${({ theme }) => theme.media.phone} {
    iframe {
      height: 280px !important;
      max-height: 40vh;
    }
  }
`

export const ReportModal = styled(Modal)`
  max-width: 500px !important;
`
const ReportContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px 30px;
`
const ReportContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 316px;
  height: 100%;
  margin: 0 auto;
`
const ReportTitle = styled.div`
  ${({ theme }) => theme.font.h3}
  margin-bottom: 30px;
`

export const ReportForm = ({ articleSlug, close }) => {
  const { accessToken } = useSession()
  const { enqueueSnackbar } = useSnackbar()
  const [isFetching, setIsFetching] = useState(false)
  const submit = async values => {
    setIsFetching(true)
    reportArticle({ values, articleSlug, accessToken }).then(() => {
      enqueueSnackbar('Article reported', { variant: 'success' })
      close()
    })
  }
  return (
    <ReportContainer>
      <ReportTitle>Report this article</ReportTitle>
      <Form
        onSubmit={submit}
        style={{ width: '100%' }}
        initialValues={{
          comments: '',
        }}
      >
        <ReportContent>
          <Form.Input
            name="comments"
            label="Comments"
            multiline
            minRows={3}
            inputProps={{
              maxLength: 600,
            }}
            required
          />
          <Button
            disabled={isFetching}
            loading={isFetching}
            style={{ marginTop: 20 }}
            type="submit"
          >
            Report article
          </Button>
        </ReportContent>
      </Form>
    </ReportContainer>
  )
}

const StyledViewCountInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 35px;
  height: 35px;
  background: ${({ theme }) => theme.colors.accentY};
  position: sticky;
  top: 73px;
  z-index: 9;
`

export const ViewCountInfo = ({ count, id }) => {
  return (
    <StyledViewCountInfo key={id}>
      {count
        ? `You still have ${count} more articles to read`
        : "You don't have any more free views"}
    </StyledViewCountInfo>
  )
}
